<template>
  <div class="search">
    <Card>
      <el-form  :model="setList" ref="setList" label-width="100px" class="demo-ruleForm">
        <el-form-item label="评分区间" prop="name">
          <el-input v-model="setList.risk_num_min"></el-input> ——
          <el-input v-model="setList.risk_num_max"></el-input>
        </el-form-item>
        <el-form-item label="风控等级">
          <el-input v-model="setList.risk_level"></el-input>
        </el-form-item>
        <el-form-item label="征信">
          <el-radio v-model="setList.cr_flag" :label="1">通过</el-radio>
          <el-radio v-model="setList.cr_flag" :label="0">不通过</el-radio>
          <!-- <el-input v-model="setList.cr_flag"></el-input> -->
        </el-form-item>
        <el-form-item label="内部预审">
          <el-radio v-model="setList.pq_flag" :label="1">通过</el-radio>
          <el-radio v-model="setList.pq_flag" :label="0">不通过</el-radio>
        </el-form-item>
        <el-form-item label="个人资产评估">
          <el-radio v-model="setList.aev_flag" :label="1">通过</el-radio>
          <el-radio v-model="setList.aev_flag" :label="0">不通过</el-radio>
        </el-form-item>
        <el-form-item label="个人信息">
          <el-radio v-model="setList.uinfo_flag" :label="1">通过</el-radio>
          <el-radio v-model="setList.uinfo_flag" :label="0">不通过</el-radio>
        </el-form-item>
        <el-form-item label="风控等级">
          <el-input v-model="setList.risk_level"></el-input>
        </el-form-item>
        <el-form-item label="原因">
          <editor ref="editor" openXss v-model="setList.reason"></editor>
        </el-form-item>
        <el-form-item label="官方认证设置">
          <el-input v-model="setList.pos_num"></el-input>
          <p>*请输入开头数字，该数字开头的商务机为官方认证设备</p>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editSystemInfo"
            >保存设置</el-button
          >
        </el-form-item>
      </el-form>
    </Card>
  </div>
</template>

<script>
import tinymec from "@/components/editor/index.vue";
export default {
  components: {
    editor: tinymec,
  },
  data(){
    return{
      radio:'',
      fileList: [],
      webStyleList:[],
      roleModalVisible:false,
      headers:{Authorization:''},
      thumb:{type:'banner'},
      setList: {
        risk_num_min:'',
        risk_num_max:'',
        risk_level:'',
        reason:'',
        pos_num:'',
      },
      store_id:''
    }
  },
  methods:{
    // 初始化数据
    formChange(data){
		  let fd = new FormData();
		  for(let i in data){
			  if(data[i]==''){
				  delete data[i]
			  }else{
				  fd.append(i,data[i])
			  }
		  }
		  return fd
	  },
    async editSystemInfo(){
      console.log(this.setList);
      for(let i in this.setList){
        if(this.setList[i]==0){
          this.setList[i]='0'
        }
      }
      let {data:res}=await this.$http.post('/editSystemInfo',this.formChange(this.setList))
      this.getSystemInfo()
      this.$Message.success('更新设置成功')
    },
    // 获取设置
    async getSystemInfo(){
      let {data:res}=await this.$http.get('getSystemInfo')
      this.setList=res.data
    },
  },
  mounted(){
    this.getSystemInfo();
  }
}
</script>

<style  lang="less" scoped>
/deep/.el-input{
  width: 300px;
}
/deep/.el-form-item{
  margin-bottom: 0;
  margin-top: 22px;
}
/deep/.el-form-item:nth-of-type(6){
  margin-bottom: 0;
  margin-top: 10px;
}
/deep/.ivu-modal{
  top: 30px;
}
/deep/.el-radio-group{
  width: 100%;
}
/deep/.el-form-item__content{
  width: 40%;
}
p{
  width: 400px;
  height: 20px;
  line-height: 20px;
  margin-top: 5px;
  font-size: 12px;
  cursor: pointer;
}
ul{
  width: 100%;
  display: flex;
  justify-content: space-around;
  li{
    height:700px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    img{
      width: 100%;
      height:100%;
      margin-bottom: 15px;
    }
  }
}
</style>